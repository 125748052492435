<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('users-list')"
        @delete="helperDeleteItem(`/client/users/${$route.params.userid}`, null, 'users-list')"
        @save="updateItem"
        @refresh="getItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(updateItem)" @reset.prevent="resetForm">

          <b-card>



              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Member Role')">
                    <validation-provider #default="{ errors }" :name="$t('Member Role')" rules="required">
                      <b-form-select v-model="currentItem.role" :options="roleOptions"
                        :placeholder="$t('Please select an Event Type')">
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('role')" class="text-danger">{{
                        $t(validation.role[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group :label="$t('Firstname')">
                    <validation-provider #default="{ errors }" :name="$t('First Name')" rules="required">
                      <b-form-input v-model="currentItem.firstname"
                        :placeholder="$t('Firstname')" />

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('firstname')" class="text-danger">{{
                        $t(validation.firstname[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group :label="$t('Name')">
                    <validation-provider #default="{errors}" :name="$t('Name')" rules="required">
                      <b-form-input v-model="currentItem.name"
                        :placeholder="$t('Name')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small v-if="Object.keys(validation).includes('name')" class="text-danger">{{
                        $t(validation.name[0])
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">

                  <b-form-group :label="$t('Position')">
                    <b-form-input v-model="currentItem.position" :placeholder="$t('Position')" />
                  </b-form-group>

                </b-col>
                <b-col cols="6">
                  <b-form-group :label="$t('Department')">
                    <b-form-input v-model="currentItem.department" :placeholder="$t('Department')" />
                  </b-form-group>

                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                    <b-form-group :label="$t('email')">
                      <validation-provider #default="{errors}" :name="$t('Email')" rules="required">
                        <b-form-input v-model="currentItem.email"
                          :placeholder="$t('email')" />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="Object.keys(validation).includes('email')" class="text-danger">{{
                          $t(validation.email[0])
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
                  {{ $t('Update') }}
                </b-button>
              </div>
          </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSidebar,
  BToast,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import { alphaNum, email, required } from '@validations'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    required,
    alphaNum,
    email,
    BFormCheckbox,
    Ripple,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {

      currentItem: {
        role: '',
        firstname: '',
        name: '',
        position: '',
        department: '',
        email: '',
      },
      loaded: true,

      roleOptions: [
        {
          value: 'admin',
          text: this.$t('admin')
        },
        {
          value: 'user',
          text: this.$t('user')
        }
      ],
      validation: {},
      validate: false,
    }
  },

  methods: {
    getItem() {
      this.helperGetItem(`/client/users/${this.$route.params.userid}`)
    },
    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/client/users/${this.$route.params.userid}`,
                  this.currentItem
              )
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },


    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    getFullname() {
      return (this.currentItem.firstname ?? this.currentItem.firstname) + ' ' + this.currentItem.name
    },

  },
  mounted() {
    this.$http.get(`/client/users/${this.$route.params.userid}`)
        .then(response => {
          this.currentItem = response.data
        })
        .catch(error => {
        })
  },
}
</script>

<style>
</style>
